
  export default {
    name: `ResetPasswordPage`,
    auth: `guest`,
    mounted() {
      // This doesn't seem to work (drawer menu is open)
      this.$store.commit(`drawerMenu/closeDrawer`)

      const query = { params: this.$route.query }
      if (query.params.status === `success`) {
        return this.$auth.loginWith(`aeq`)
      }
      let next = this.$config.public.baseApi
      const currentURL = window.location.href.split(`?`)[0]
      next += `/initial_password/?next=${currentURL}&`
      next += new URLSearchParams(query.params).toString()
      window.location.replace(next)
    }
  }
